import { IPost } from "..";
import XorShaderCanvas from "../../../components/XorShaderCanvas";
import Figure from "./Figure";

const postDetails = {
  id: "2024-01-part-2",
  title: "My post",
  date: "2024-01-10",
};

const post: IPost = {
  ...postDetails,
  content: <Figure N={100} aspectRatio="16/9" func="(i0 ^ j0) % 5 == 0" />,
};

{
  /* <Example func="float(pseudoRandomMap(i0 ^ j0) % 11 % 9 % 7) / 7.0" /> */
}
{
  /* <Example func="pow(float(pseudoRandomMap(abs(i0 ^ j0)) % 257 % 17 % 7) / 7.0,0.5)" /> */
}
{
  /* <Example func="pseudoRandomMap(abs(i0 ^ j0)) % 5 < 2" />
      <Example func="pseudoRandomMap2(abs(i0 ^ j0)) % 5 == 0" />
      <Example func="pseudoRandomMap3(abs(i0 ^ j0)) % 5 == 0" />
      <Example func="pseudoRandomMap(abs(i0 ^ j0)) % 11 % 9 % 7 == 0" />
      <Example func="pseudoRandomMap2(abs(i0 ^ j0)) % 11 % 9 % 7 == 0" />
      <Example func="pseudoRandomMap3(abs(i0 ^ j0)) % 11 % 9 % 7 == 0" /> */
}
{
  /* <Example func="pseudoRandomMap2(abs(i0 ^ j0)) % 5 == 0" />
      <Example func="pseudoRandomMap2(abs(i0 ^ j0)) % 257 % 15 % 7 == 0" /> */
}
{
  /* <Example label="A power of two" func="abs(i0 ^ j0) % 64 == 0" />
      <Example label="One below a power of two" func="abs(i0 ^ j0) % 15 == 0" />
      <Example label="One above a power of two" func="abs(i0 ^ j0) % 17 == 0" />
      <Example
        label="Fermat primes"
        func="abs(i0 ^ j0) % 4097 % 2049 % 1025 % 513 % 257 % 129 % 65 % 33 % 17 % 5 == 0"
      />
      <Example label="Some odd numbers" func="abs(i0 ^ j0) % 9 % 7 % 5 == 0" />
      <Example label="Some odd numbers" func="abs(i0 ^ j0) % 11 % 9 % 7 == 0" />
      <Example
        label="Some odd numbers"
        func="abs(i0 ^ j0) % 13 % 11 % 9 == 0"
      />
      <Example
        label="Some odd numbers"
        func="abs(i0 ^ j0) % 15 % 13 % 4 == 0"
      />
      <Example
        label="Mersenne primes"
        func="abs(i0 ^ j0) % 131071 % 8191 % 127 % 31 % 7 % 3 == 0"
      />
      <Example
        label="Mersenne primes (except 3)"
        func="abs(i0 ^ j0) % 131071 % 8191 % 127 % 31 % 7 == 0"
      />
      <Example
        label="Some of the Fermat primes"
        func="abs(i0 ^ j0) % 257 % 17 % 5 == 0"
      />
      <Example
        label="Some of the Fermat primes and changing over time"
        func="abs((i0 ^ j0) ^ int(10.0*iTime)) % 257 % 17 % 5 == 0"
      />
      <Example func="abs((i0 ^ j0) + int(10.0*iTime + float(i0)/10.0)) % 17 % 16 == 0" />
      <Example func="abs((i0 ^ j0) + int(10.0*iTime + float(i0)/10.0)) % 33 % 32 == 0" />
      <Example func="abs((i0 ^ j0) + int(10.0*iTime + float(i0)/10.0)) % 65 % 64 == 0" />
      <Example func="abs((i0 ^ j0) + int(10.0*iTime + float(j0)/10.0)) % 65 % 64 == 0" />
      <Example func="abs((i0 ^ j0) + int(10.0*iTime + sqrt(float(i0*i0)+float(j0*j0)) % 65 % 64 == 0" />
      <Example func="abs((i0 ^ j0) + int(10.0*iTime + float(j0)/10.0)) % 65 % 64 % 63 == 0" />
      <Example func="abs((i0 ^ j0) + int(5.0*iTime + float(i0)/10.0)) % 65 % 64  == 0" />
      <Example func="abs((i0 ^ j0) + 4*int(3.0*iTime + float(i0)/10.0)) % 92 % 83 % 4  == 0" /> */
}

export default post;
