import { IPost } from "..";
import Figure from "./Figure";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const postDetails = {
  id: "2024-01-part-1",
  title: "My post",
  date: "2024-01-10",
};

const getRandomInts = () => {
  const randomInts: number[] = [];
  for (let i = 0; i < 5; i++) {
    randomInts.push(Math.floor(Math.random() * 98) + 3);
  }
  // only keep the ones that are smaller than the previous
  console.log("old", randomInts);
  console.log(
    "new",
    randomInts.filter((val, ind) => {
      return ind === 0 || val < randomInts[ind - 1];
    })
  );
  const filteredRandomInts: number[] = [randomInts[0]];
  for (let i = 1; i < randomInts.length; i++) {
    if (randomInts[i] < filteredRandomInts[filteredRandomInts.length - 1]) {
      filteredRandomInts.push(randomInts[i]);
    }
  }
  return filteredRandomInts;
};

const FigureWithSlider = ({
  values,
  funcTemplate,
  titleTemplate,
  intervalInSeconds,
  defaultValue,
}: {
  values: number[];
  funcTemplate: (value: number) => string;
  titleTemplate: (value: number) => React.ReactNode;
  intervalInSeconds: number;
  defaultValue: number;
}) => {
  const indexOfDefault = values.findIndex((value) => value === defaultValue);
  const [n, setN] = useState(indexOfDefault);
  const [play, setPlay] = useState(false);
  useEffect(() => {
    const increment = () => setN((n) => (n + 1) % values.length);
    if (play) {
      increment();
      const interval = setInterval(increment, intervalInSeconds * 1000);
      return () => clearInterval(interval);
    }
  }, [play]);
  return (
    <>
      <Figure
        N={100}
        aspectRatio="2"
        func={funcTemplate(values[n])}
        title={titleTemplate(values[n])}
        footer={
          <Row className="g-1 align-items-center pt-1">
            <Col>
              <Form.Range
                min={0}
                max={values.length - 1}
                value={n}
                onChange={(e) => {
                  setN(parseInt(e.target.value));
                  setPlay(false);
                }}
              />
            </Col>
            <Col xs="auto">
              <Button
                onClick={() => setPlay((play) => !play)}
                style={{ width: "32px", height: "32px" }}
                className="p-0"
              >
                {play ? "⏸" : "⏵"}
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
};

const BlogPost = () => {
  // const [randomInts, setRandomInts] = useState<number[]>(getRandomInts());

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setRandomInts(getRandomInts());
  //   }, 2000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div>
      <h1>{postDetails.title}</h1>
      <p>{postDetails.date}</p>
      <h2>XOR textures</h2>
      <p>
        A while ago my attention was caught by XOR textures - images constructed
        using the{" "}
        <Link to="https://en.wikipedia.org/wiki/Bitwise_operation">
          bitwise XOR operator
        </Link>
        .
      </p>
      <Figure N={128} aspectRatio="2" func="0.5 + float((i0 ^ j0)) / 256.0" />
      <p>
        In particular, the isolines of <code>i^j</code> make interesting
        patterns:
      </p>
      <FigureWithSlider
        values={Array.from(Array(128).keys())}
        defaultValue={5}
        funcTemplate={(value) => `abs(i0 ^ j0) == ${value}`}
        titleTemplate={(value) => <code>i^j == {value}</code>}
        intervalInSeconds={0.2}
      />
      <p>
        Have been playing with patterns like <code>i^j % N == 0</code>.
      </p>
      <FigureWithSlider
        values={Array.from(Array(128).keys()).slice(2)}
        defaultValue={5}
        funcTemplate={(value) => `(i0 ^ j0) % ${value} == 0`}
        titleTemplate={(value) => <code>i^j % {value} == 0</code>}
        intervalInSeconds={1}
      />
      Primes
      <FigureWithSlider
        values={[
          2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61,
          67, 71, 73, 79, 83, 89, 97,
        ]}
        defaultValue={13}
        funcTemplate={(value) => `(i0 ^ j0) % ${value} == 0`}
        titleTemplate={(value) => <code>i^j % {value} == 0</code>}
        intervalInSeconds={1}
      />
      <Figure
        N={100}
        aspectRatio="2"
        func="abs(i0 ^ j0) % 5 == 0"
        title={<code>i^j % 5 == 0</code>}
        scroll
      />
      <Figure
        N={100}
        aspectRatio="2"
        func="abs(i0 ^ j0) % 11 == 0"
        title={<code>i^j % 11 == 0</code>}
        scroll
      />
      <p>These are nice but get increasingly sparse as N gets large.</p>
      <h2>Repeated modulo</h2>
      <p>
        Had the idea to try mixing them using repeated modulo operations{" "}
        <code>(i^j) % N1 % N2 % N3 ... % N == 0</code>.
      </p>
      <p>Then plotting x % N1 ... % N == 0 I realised that the</p>
      <Figure
        N={100}
        aspectRatio="2"
        func="abs(i0 ^ j0) % 257 % 17 % 5 == 0"
        title={<code>i^j % 257 == 0</code>}
        scroll
      />
      <p>Or</p>
      <Figure
        N={100}
        aspectRatio="2"
        func="abs(i0 ^ j0) % 11 % 9 % 7 == 0"
        title={<code>i^j % 11 % 9 % 7 == 0</code>}
        scroll
      />
      <p>Or</p>
      <Figure
        N={100}
        aspectRatio="2"
        func="abs(i0 ^ j0) % 12 % 11 == 0"
        title={<code>i^j % 21 % 20 == 0</code>}
        scroll
      />
      <Figure
        N={100}
        aspectRatio="2"
        func="abs(i0 ^ j0) % 13 % 12 == 0"
        title={<code>i^j % 21 % 20 == 0</code>}
        scroll
      />
      <Figure
        N={200}
        aspectRatio="2"
        func="abs(i0 ^ j0) % 20 % 19 == 0"
        title={<code>i^j % 20 % 19 == 0</code>}
        scroll
      />
      {/* <ShaderCanvas N={100} aspectRatio="2"
        label="Random"
        func={`abs(i0 ^ j0) % ${randomInts.join(` % `)} == 0`}
      /> */}
      {/* <ShaderCanvas N={100} aspectRatio="2" func="(i0 ^ j0) % 5 == 0" /> */}
      <h2>Pseudo-random</h2>
      <Figure
        N={100}
        aspectRatio="2"
        func="abs(pseudoRandomMap(i0 ^ j0)) % 5 == 0"
        scroll
        shiftY
      />
      <p>Pseudo-random gradient (with contrast boost and zoom out)</p>
      <Figure
        N={200}
        aspectRatio="2"
        func="easeInOutSine(float(abs(pseudoRandomMap(i0 ^ j0)) % 5) / 4.0)"
        scroll
        shiftY
      />
      {/* <Figure
        N={100}
        aspectRatio="2"
        func="pseudoRandomMap(abs(i0 ^ j0)) % 3"
      />
      <Figure
        N={100}
        aspectRatio="2"
        func="pseudoRandomMap(i0 ^ j0) % 4 / 3.0"
      />
      <Figure
        N={100}
        aspectRatio="2"
        func="pseudoRandomMap(abs(i0 ^ j0)) % 5"
      />
      <Figure
        N={100}
        aspectRatio="2"
        func="pseudoRandomMap(abs(i0 ^ j0)) % int(2.+iTime)"
      /> */}
    </div>
  );
};

const post: IPost = { ...postDetails, content: <BlogPost /> };

export default { ...postDetails, content: <BlogPost /> };
