import { useState, useRef, useEffect } from "react";

import { Row, Col, ButtonGroup, Button } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Body from "../components/Body";

interface ICollaborator {
  name: string;
  url: string;
}
interface IPortfolioItem {
  path: string;
  tags: string[];
  year: number;
  month: number;
  collaborators: ICollaborator[];
}

var portfolio = require("../portfolio.json")
  .slice(0)
  .reverse() as IPortfolioItem[];

const years = portfolio
  .map((item) => item.year)
  .filter((v, i, a) => a.indexOf(v) === i)
  .sort()
  .reverse();

const generatePosterUri = (item: IPortfolioItem) => {
  const arr = item.path.split(".");
  const extension = arr.pop();
  const name = arr.join(".");
  return name + "_thumbnail.png";
};

const generateThumbnailUri = (item: IPortfolioItem) => {
  const arr = item.path.split(".");
  const extension = arr.pop();
  const name = arr.join(".");
  if (item.tags.includes("animated")) {
    return name + "_thumbnail.gif";
  } else {
    return name + "_thumbnail.png";
  }
};

const Item = ({ item }: { item: IPortfolioItem }) => {
  const ref = useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen(ref);
  return (
    <Col
      key={item.path}
      style={{ display: "inline", padding: 3 }}
      className="my-auto"
    >
      <a href={item.path}>
        <div
          style={{
            position: "relative",
            display: "block",
            width: "100%",
            height: 0,
            paddingBottom: "100%",
          }}
        >
          {item.tags.includes("animated") && (
            <div
              ref={ref}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
            >
              <img width="100%" src={generatePosterUri(item)} />
            </div>
          )}
          <div
            ref={ref}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            {onScreen && (
              <img width="100%" src={generateThumbnailUri(item)} />
              // <video
              //   poster={generatePosterUri(item.filename)}
              //   width="100%"
              //   style={{ display: "block" }}
              //   autoPlay
              //   loop
              //   muted
              // >
              //   <source src={generateThumbnailUri(item.filename)} type="video/webm" />
              // </video>
            )}
          </div>
        </div>
      </a>
    </Col>
  );
};

const ItemsGrid = ({ items }: { items: IPortfolioItem[] }) => {
  return (
    <Row xs={2} md={2} lg={4} className="g-0">
      {items.map((item) => (
        <Item key={item.path} item={item} />
      ))}
    </Row>
  );
};

// Hook
function useOnScreen(ref: any, rootMargin = "0px") {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
}

export const GalleryPage = () => {
  const [filter, setFilter] = useState("favourite");
  return (
    <>
      <Header />
      <Body>
        <Row className="g-0">
          <Col>
            <ButtonGroup className="d-flex">
              <Button
                style={{ width: "100%" }}
                variant={filter === "favourite" ? "primary" : "secondary"}
                onClick={() => setFilter("favourite")}
              >
                Favorites
              </Button>
              <Button
                style={{ width: "100%" }}
                variant={filter === "animated" ? "primary" : "secondary"}
                onClick={() => setFilter("animated")}
              >
                All animations
              </Button>
              <Button
                style={{ width: "100%" }}
                variant={filter === "static" ? "primary" : "secondary"}
                onClick={() => setFilter("static")}
              >
                All stills
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        {years.map((year) => {
          const items = portfolio.filter(
            (item) => item.year === year && item.tags.includes(filter)
          );
          return (
            items.length > 0 && (
              <div key={year} className="mt-3">
                <h2>{year}</h2>
                <ItemsGrid items={items} />
              </div>
            )
          );
        })}
      </Body>
      <Footer />
    </>
  );
};
