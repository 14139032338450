import Header from "../components/Header";
import Footer from "../components/Footer";
import Body from "../components/Body";

import posts from "../blog/posts";
import { Link } from "react-router-dom";
import { IPost } from "../blog/posts";

const BlogPostCard = ({ post }: { post: IPost }) => (
  <div className="mt-4">
    <Link key={post.id} to={`/blog/${post.id}`}>
      <h1>{post.title}</h1>
    </Link>
    {post.date}
  </div>
);

const BlogContents = () => {
  return (
    <div className="d-flex-column">
      {posts.map((post) => (
        <BlogPostCard key={post.id} post={post} />
      ))}
    </div>
  );
};

export const BlogContentsPage = () => {
  return (
    <>
      <Header />
      <Body>
        <BlogContents />
      </Body>
      <Footer />
    </>
  );
};
