import { Link, useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Body from "../components/Body";

import posts from "../blog/posts";

export const BlogPostPage = () => {
  const { postId } = useParams();
  const post = posts.find((post) => post.id === postId);
  return (
    <>
      <Header />
      <Body>{post ? post.content : <>Post not found</>}</Body>
      <Footer />
    </>
  );
};
