import { Canvas, extend, useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { Mesh, ShaderMaterial } from "three";

const FragmentShaderComponent = ({
  N,
  func,
  colorFunc,
  scroll,
  shiftY,
}: {
  N: number;
  func: string;
  colorFunc: string;
  scroll: boolean;
  shiftY: boolean;
}) => {
  const meshRef = useRef<Mesh>(null);
  const { size, viewport } = useThree();
  const [planeSize, setPlaneSize] = useState([viewport.width, viewport.height]);

  useEffect(() => {
    setPlaneSize([viewport.width, viewport.height]);
  }, [viewport]);

  const fragmentShader = `
    uniform float iTime;
    uniform vec2 iResolution;
    uniform float iWidth;
    const float PI = 3.1415926535897932384626433832795;

    int pseudoRandomMap(int x) {
      x = ((x >> 16) ^ x) * int(0x45d9f3b);
      x = ((x >> 16) ^ x) * int(0x45d9f3b);
      x = (x >> 16) ^ x;
      return x;
    }

    float easeInOutSine(float x) {
      return -(cos(PI*x) - 1.0) / 2.0;
    }

    float func(int i0, int j0) {
      return float(${func});
    }

    vec3 colorFunc(float x) {
      return ${colorFunc};
    }

    void main() {
      // x from -iWidth to iWidth
      vec2 xy = iWidth * (gl_FragCoord.xy - iResolution) / iResolution.xx;
      ${scroll ? "xy.x += 10.0 * iTime;" : ""}
      ${shiftY ? "xy.y += 1000.0;" : ""}

      int i0 = int(floor(xy.x));
      int j0 = int(floor(xy.y));

      float x = func(i0, j0);
      gl_FragColor = vec4(colorFunc(x), 1);
    }
  `;

  // Create a shader material
  const shaderMaterial = new ShaderMaterial({
    fragmentShader,
    uniforms: {
      iTime: { value: 0 },
      iResolution: { value: [size.width, size.height] },
      iWidth: { value: N.toFixed(1) },
    },
  });

  useFrame(({ clock }) => {
    shaderMaterial.uniforms.iTime.value = clock.getElapsedTime();
  });

  return (
    <mesh ref={meshRef} material={shaderMaterial}>
      <planeGeometry attach="geometry" args={[planeSize[0], planeSize[1]]} />
    </mesh>
  );
};
extend({ FragmentShaderComponent });

const XorShaderCanvas = ({
  N,
  func,
  colorFunc,
  aspectRatio,
  scroll,
  shiftY,
}: {
  N: number;
  func: string;
  colorFunc: string;
  aspectRatio: string;
  scroll: boolean;
  shiftY: boolean;
}) => {
  return (
    <Canvas
      orthographic
      camera={{ zoom: 1, position: [0, 0, 1] }}
      style={{ width: "100%", aspectRatio }}
    >
      <FragmentShaderComponent
        N={N}
        func={func}
        colorFunc={colorFunc}
        scroll={scroll}
        shiftY={shiftY}
      />
    </Canvas>
  );
};

export default XorShaderCanvas;
