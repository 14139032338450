import React from "react";
import { Container } from "react-bootstrap";

const Body = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="App-body">
      <Container className="mt-4">{children}</Container>
    </div>
  );
};

export default Body;
