import XorShaderCanvas from "../../../components/XorShaderCanvas";

const Figure = ({
  N,
  func,
  aspectRatio,
  scroll,
  shiftY,
  title,
  footer,
}: {
  N: number;
  func: string;
  aspectRatio: string;
  scroll?: boolean;
  shiftY?: boolean;
  title?: React.ReactNode;
  footer?: React.ReactNode;
}) => {
  const v0 = `vec3(0.988,0.973,0.937)`;
  const v1 = `vec3(1,0.6,0.588)`;
  const colorFunc = `${v0} + (1.0-clamp(x,0.0,1.0)) * (${v1} - ${v0})`;
  return (
    <div>
      <div className="d-flex justify-content-around">{title}</div>
      <div>
        <XorShaderCanvas
          N={N}
          aspectRatio={aspectRatio}
          func={func}
          colorFunc={colorFunc}
          scroll={scroll ?? false}
          shiftY={shiftY ?? false}
        />
      </div>
      <div>{footer}</div>
    </div>
  );
};

export default Figure;
