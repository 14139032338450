import { SocialIcon } from "react-social-icons";

const Socials = () => (
  <div>
    <div style={{ padding: 2, display: "inline" }}>
      <a rel="me" href="https://genart.social/@sjpalmer1994">
        <img src="/mastodon.svg" width="50px" />
      </a>
    </div>
    <div style={{ padding: 2, display: "inline" }}>
      <SocialIcon url="https://twitter.com/sjpalmer1994" bgColor="#ea6864" />
    </div>
    <div style={{ padding: 2, display: "inline" }}>
      <SocialIcon url="https://instagram.com/sjpalmer1994" bgColor="#ea6864" />
    </div>
    <div style={{ padding: 2, display: "inline" }}>
      <SocialIcon url="https://reddit.com/u/sjpalmer94" bgColor="#ea6864" />
    </div>
  </div>
);

export default Socials;
