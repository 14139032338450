import { BrowserRouter, Routes, Route } from "react-router-dom";

import { GalleryPage } from "./pages/gallery";
import { BlogPostPage } from "./pages/BlogPostPage";
import { BlogContentsPage } from "./pages/BlogContentsPage";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<GalleryPage />} />
          <Route path="/blog/:postId" element={<BlogPostPage />} />
          <Route path="/blog" element={<BlogContentsPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
