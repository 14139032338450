import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Socials from "./Socials";

const Header = () => {
  const location = useLocation();
  const activeKey = location.pathname.startsWith("/blog") ? "/blog" : "/";
  return (
    <div style={{ textAlign: "center" }}>
      {/* <Navbar expand="lg" variant="dark" bg="primary">
        <Container>
          <Navbar.Brand as={Link} to="/">
            S J Palmer
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav activeKey={activeKey}>
              <Nav.Item>
                <Nav.Link as={Link} to="/" eventKey="/">
                  Gallery
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/blog" eventKey="/blog">
                  Blog
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
      <Container className="mt-3">
        <Link to="/">
          <img src="/profile_crop_circle.png" width="150px" />
        </Link>
        <h1>Samuel J Palmer</h1>
        <Socials />
        <Row className="justify-content-md-center g-2">
          <Col md="auto"></Col>
          <Col md="auto"></Col>
          <Col md="auto"></Col>
        </Row>
      </Container>
    </div>
  );
};

export default Header;
