import posts_2024_01 from "./2024-01";

export interface IPost {
  id: string;
  title: string;
  date: string;
  content: React.ReactNode;
}

const posts: IPost[] = [...posts_2024_01];

export default posts;
