export const Footer = () => {
  return (
    <>
      <div
        className="App-footer mt-3"
        style={{ textAlign: "center", paddingBottom: "16px" }}
      >
        ~
      </div>
    </>
  );
};

export default Footer;
